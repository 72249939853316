.react-calendar {
  width: 325px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.125em;
  border: none;
  padding: 10px;
}

.react-calendar__navigation button {
  min-width: 42px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #7a6bd0;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #7a6bd0;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #7a6bd0;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #7a6bd0;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #7a6bd0;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #7a6bd0;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #7a6bd0;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #7a6bd0;
  color: white;
}
