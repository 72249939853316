html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a.footer-link {
  height: 14px;
  margin-left: 4px;
  opacity: 35%;
  text-align: center;
  display: inline-table;
  position: relative;
  width: 100%;
}

.root-grid {
  min-height: 100vh;
  margin: 0 auto !important;
  background-color: #fff;
  padding: 0 50px;
}

.language-selector {
  max-height: 32px;
}

.language-selector > div {
  padding-left: 0;
  padding-right: 16px !important;
}

.language-selector p {
  display: none;
}

.language-menu-item {
  max-height: 32px !important;
}

@media (min-width: 515px) {
  html {
    background-color: #f7f7f6;
  }

  .root-grid {
    min-height: 768px !important;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .bank-icon {
    margin: 0 20px;
  }

  .pre-load,
  .big,
  .small {
    margin: 7px !important;
  }
}

@media (max-width: 425px) {
  .root-grid {
    padding: 0 20px;
  }
}
